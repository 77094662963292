import Vue from 'vue'
import App from './App.vue'
/* 国际化 */
import { i18n } from '@/i18n'
/* 路由 */
import router from './router'
/* swiper */
import 'swiper/dist/css/swiper.min.css';

/* 重置样式 */
import './assets/css/reset.min.css'

/* jquery */
import 'jquery'

// /* bootstarp */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

/* animate.css */
import 'animate.css'
/* 头部组件 */
import Header from './components/Header'
Vue.component(Header.name,Header)


/* 尾部组件 */
import Footer from './components/Footer'
Vue.component(Footer.name,Footer)

/* 回到顶部 */
import GoTop from './components/GoTop'
Vue.component(GoTop.name,GoTop)
Vue.config.productionTip = false
/* 动态标题名 */
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
});

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
