export const h = {
    // 通用
    c_btn1: 'Learn more',
    c_btn2: 'Chromaticity',
    c_btn3: 'Contact us',
    // 头部
    translate: 'change language chinese', // 语言切换
    company: 'YUEMEI', // 品牌
    service: '7x24-hour service for you', // 为您服务
    contactUs: 'Hurry up and contact us!', // 联系我们
    // 菜单
    home: 'Home', // 首页
    embroidery: 'Embroidery', // 刺绣
    printting: 'Printting', // 印刷
    others: 'Others', // 其他产品
    financing: 'Financing', // 融资
    blogs: 'Blogs', // 博客
    exhibitions: 'Exhibitions',// 展览活动
    connectUs: 'Connect us', // 联系我们,
    // banner
    b_r1c1: 'Give full play to your customization', // 充分发挥您的定制
    b_r1c2: 'The potential of the clothing business', // 服装业务的潜力
    b_r2c1: 'Utilize the best equipment, support, and training in the industry to initiate or develop profitable custom clothing businesses', //利用业内最好的设备、支持和培训启动或发展盈利的定制服装业务
    // video
    v_r1: 'Zhejiang Yuelong Sewing Equipment Co., Ltd. (hereinafter referred to as Yuelong Company) was established in 2001, and "Yuemei Embroidery Machine" is the product trademark. Yuelong Company is a professional multi head computerized embroidery machine manufacturer that integrates product research and development, design, production, sales, and stock management.',
    v_r2: 'Yuelong Company has a registered capital of 75 million yuan, a factory area of approximately 150 acres, a construction area of over 100000 square meters, and fixed assets of approximately 630 million yuan. It is a leading enterprise in the domestic multi head computer embroidery machine industry. The company currently has 620 employees, including 67 research and development technicians, 37 with a bachelors degree or above (including 2 graduate students), and 51 senior employees.',
    v_r3: 'Integrity, empathy, harmony, and innovation are the values of enterprises; "Dedicated to building a world-class computer embroidery machine manufacturer" is the companys must think; "Never mediocre, never give up, never satisfied" is the spirit of continuous progress in enterprises; "Embroidery makes life better" is the mission of enterprises.',
    v_r4: 'Yuelong Company has been selected as a national high-tech enterprise and has passed ISO-9001 quality management system certification, IS0-14001 environmental management system certification, and international CE certification.',
    v_r5: 'Has won honors such as the single champion product of the Ministry of Industry and Information Technology, the first set of products in Zhejiang Province, Zhejiang Manufacturing Boutique, Zhejiang Science and Technology Award, Zhejiang Famous Brand, Zhejiang Export Famous Brand, Zhejiang Invisible Champion Cultivation Enterprise, Shaoxing First Meal Product, Shaoxing Invisible Champion, and Shaoxing Famous Trademark.',
    v_r6: 'Yuelong Company is the main drafting enterprise of the "Made in Zhejiang" standard "Computer Controlled High Speed Engraving Embroidery Machine", and the main drafting enterprise of the national standard GB/T30402.2-201. It is also one of the top 30 enterprises in China light industry equipment manufacturing, the top 10 enterprises in China light industry sewing machinery embroidery industry, and a leading enterprise in Zhejiang Provinces "Quality Zhejiang Goods" export.',
    // 为什么选择我们
    u_r1: 'THE REASON TO CHOOSING US',
    u_r2c1: 'Superior customer support',
    u_r2c2: 'YUEMEI customers can enjoy a one-year warranty, free training, and lifelong use of our service department 7 days a week - no additional payment of 10 cents.',
    u_r3c1: 'Directly from the factory to your doorstep',
    u_r3c2: 'Purchasing directly from the manufacturer means you will receive the most direct support, and you do not need to pay any additional fees to reduce the costs of intermediaries.',
    u_r4c1: 'The highest quality of clothing customization',
    u_r4c2: 'YUEMEI machines are heavy-duty and designed specifically for 24/7 operation, conquering every project with precision and speed.',
    // 融资
    g_r1: 'Unparalleled financing with 100% satisfaction',
    g_r2: 'Imagine starting or developing your business by simply paying for phone bills or internet traffic fees. It has never been so easy to profit from your business through special promotional financing. What upfront expenses did you save? The money in your pocket can be used for advertising and expanding your business.',
    // 明星产品
    p_r1c1: 'Star products',
    p_r1c2: 'Whether you are a beginner or a certified embroidery expert,',
    p_r1c3: 'Our easy-to-use machines will help you get started and run immediately.',
    p_title: 'Embroidery enthusiasts',
    p_r1: 'Double headed 12 needle entry-level',
    p_r2: 'Single head mixed show 12 needles',
    p_r3: '12 needles',
    p_t1: 'Using this',
    p_t2: 'Embroidery creates stunning multi-color top-level projects.',
    // 培训
    t_r1: 'Start making money immediately with free training and support',
    t_r2: 'Whether you are a beginner in embroidery or a first-time buyer of YUEMEI machines, each YUEMEI machine package includes free practical training and unlimited lifetime support for 7 days a week. We will immediately assist you in getting started and running so that you can start profiting from your investment immediately.',
    // 提高您的刺绣设计技能
    c_r1: 'Improve your embroidery design skills',
    c_r2: 'No experience? No problem. Beginners can easily digitize and edit designs using this software that is suitable for beginners. Each YUEMEI embroidery machine includes dedicated design software.',
    // 运行情况
    r_r1: 'Experienced design team',
    r_r2: 'After years of production and manufacturing experience, a reliable set of machine parameters has been improved. ',
    r_r3: ' An experienced design team produces qualified standard variant design drawings based on the summarized machine parameters, ensuring the quality of customized products.',
    // 您身边的纺织专家
    f_r1:'The textile experts around you',
    f_r2:'7x24 hours of excellent textile support',
}