export const h = {
    // 通用
    c_btn1: 'Erfahren Sie mehr',
    c_btn2: 'Farbigkeit anzeigen',
    c_btn3: 'Kontaktieren Sie uns',
    // 头部
    translate: 'change language english',
    company: 'YUEMEI',
    service: '7x24 Stunden Service für Sie',
    contactUs: 'Beeilen Sie sich und kontaktieren Sie uns!',
    // 菜单
    home: 'Startseite',
    embroidery: 'Stickerei',
    printting: 'Druck',
    others: 'Sonstige Produkte',
    financing: 'Finanzierung',
    blogs: 'Blog',
    exhibitions: 'Ausstellungstätigkeiten',
    connectUs: 'Kontaktieren Sie uns',
    // banner
    b_r1c1: 'Geben Sie vollen Spielraum für Ihre Anpassung',
    b_r1c2: 'Das Potenzial des Bekleidungsgeschäfts',
    b_r2c1: 'Nutzen Sie die beste Ausrüstung, Unterstützung und Schulung der Branche, um profitable maßgeschneiderte Bekleidungsgeschäfte zu initiieren oder zu entwickeln',
    // video
    v_r1: 'Zhejiang Yuelong Sewing Equipment Co., Ltd. (im Folgenden als Yuelong Company bezeichnet) wurde im 2001 gegründet, und "Yuemei Stickmaschine" ist die Produktmarke. Yuelong Company ist ein professioneller Multi-Kopf-Computer-Stickmaschinenhersteller, der Produktforschung und -entwicklung, Design, Produktion, Vertrieb und Lagerverwaltung integriert.',
    v_r2: 'Yuelong Company hat ein eingetragenes Kapital von 75 Millionen Yuan, eine Fabrikfläche von ca. 150 Hektar, eine Baufläche von über 100000 Quadratmetern und Anlagevermögen von ca. 630 Millionen Yuan. Es ist ein führendes Unternehmen in der inländischen Multi Kopf Computer Stickmaschinenindustrie. Das Unternehmen hat derzeit 620 Mitarbeiter, darunter 67 Forschungs- und Entwicklungstechniker, 37 mit einem Bachelor-Abschluss oder höher (einschließlich 2-Absolventen) und 51 leitende Angestellte.',
    v_r3: 'Integrität, Empathie, Harmonie und Innovation sind die Werte von Unternehmen; "Gewidmet dem Aufbau eines Weltklasse-Computer-Stickmaschinenherstellers" muss das Unternehmen denken; "Nie mittelmäßig, nie aufgeben, nie zufrieden" ist der Geist des kontinuierlichen Fortschritts in Unternehmen; "Stickerei macht das Leben besser" ist die Mission der Unternehmen.',
    v_r4: 'Yuelong Company wurde als nationales High-Tech-Unternehmen ausgewählt und hat ISO-9001 Qualitätsmanagementsystem-Zertifizierung, IS0-14001 Umweltmanagementsystem-Zertifizierung und internationale CE-Zertifizierung bestanden.',
    v_r5: 'Hat Auszeichnungen wie das einzelne Championprodukt des Ministeriums für Industrie und Informationstechnologie gewonnen, der erste Satz von Produkten in der Provinz Zhejiang, Zhejiang Manufacturing Boutique, Zhejiang Science and Technology Award, Zhejiang Berühmte Marke, Zhejiang Export Berühmte Marke, Zhejiang Invisible Champion Kultivation Unternehmen, Shaoxing First Meal Produkt, Shaoxing Unsichtbare Champion Champion und Shaoxing Berühmte Marke.',
    v_r6: 'Yuelong Company ist das Hauptentwurfsunternehmen des "Made in Zhejiang" -Standards "Computer Controlled High Speed Gravure Sticky Machine" und des Hauptentwurfsunternehmens des nationalen Standards GB/T30402.2-201. Es ist auch eines der Top 30 Unternehmen in Chinas Leichtindustrie Ausrüstungsherstellung, die Top 10 Unternehmen in Chinas Leichtindustrie Nähmaschinen Stickindustrie und ein führendes Unternehmen im Zhejiang Provinz "Quality Zhejiang Goods" Export.',
    // 为什么选择我们
    u_r1: 'Warum wählen Sie unseren Service',
    u_r2c1: 'Hervorragender Kundenservice',
    u_r2c2: 'YUEMEI-Kunden können eine einjährige Garantie, kostenlose Schulung und lebenslange Nutzung unserer Serviceabteilung 7-Tage die Woche ohne zusätzliche Zahlung von 10-Cent genießen.',
    u_r3c1: 'Direkt von der Fabrik bis zur Haustür',
    u_r3c2: 'Der direkte Kauf beim Hersteller bedeutet, dass Sie den direktesten Support erhalten und keine zusätzlichen Gebühren zahlen müssen, um die Kosten der Vermittler zu senken.',
    u_r4c1: 'Die höchste Qualität der Anpassung der Kleidung',
    u_r4c2: 'YUEMEI Maschinen sind hochbelastbar und speziell für den 24/7-Betrieb konzipiert und meistern jedes Projekt mit Präzision und Geschwindigkeit.',
    // 融资
    g_r1: 'Einzigartige Finanzierung mit 100% Zufriedenheit',
    g_r2: 'Stellen Sie sich vor, Ihr Unternehmen zu starten oder zu entwickeln, indem Sie einfach Telefonrechnungen oder Internetverkehrsgebühren bezahlen. Noch nie war es so einfach, durch spezielle Förderfinanzierungen von Ihrem Unternehmen zu profitieren. Welche Vorkosten haben Sie gespart? Das Geld in Ihrer Tasche kann für Werbung und Expansion Ihres Unternehmens verwendet werden.',
    // 明星产品
    p_r1c1: 'Star-Produkte',
    p_r1c2: 'Ob Anfänger oder zertifizierter Stickexperte,',
    p_r1c3: 'Unsere einfach zu bedienenden Maschinen helfen Ihnen, sofort loszulegen und zu laufen.',
    p_title: 'Stickbegeisterte',
    p_r1: 'Doppelköpfige 12-Nadel-Einsteigerstufe',
    p_r2: 'Einzelkopf gemischt zeigen 12 Nadeln',
    p_r3: '12-Maschen',
    p_t1: 'Mit diesem',
    p_t2: 'Stickerei schafft atemberaubende mehrfarbige Top-Level-Projekte.',
    // 培训
    t_r1: 'Beginnen Sie sofort mit kostenlosem Training und Support Geld zu verdienen',
    t_r2: 'Egal, ob Sie Anfänger in der Stickerei sind oder zum ersten Mal YUEMEI Maschinen kaufen, jedes YUEMEI Maschinenpaket beinhaltet kostenlose praktische Schulungen und unbegrenzten lebenslangen Support für sieben Tage die Woche. Wir unterstützen Sie umgehend beim Start und Betrieb, damit Sie sofort von Ihrer Investition profitieren können.',
    // 提高您的刺绣设计技能
    c_r1: 'Verbessern Sie Ihre Stickdesign Fähigkeiten',
    c_r2: 'Keine Erfahrung? Kein Problem. Anfänger können Designs einfach digitalisieren und bearbeiten mit dieser Software, die für Anfänger geeignet ist. Jede YUEMEI Stickmaschine enthält eine spezielle Design-Software',
    // 运行情况
    r_r1: 'Erfahrenes Designteam',
    r_r2: 'Nach jahrelanger Produktions- und Fertigungserfahrung wurde ein zuverlässiger Satz von Maschinenparametern verbessert.',
    r_r3: 'Ein erfahrenes Konstruktionsteam erstellt qualifizierte Standardvarianten-Konstruktionszeichnungen basierend auf den zusammengefassten Maschinenparametern und sichert die Qualität kundenspezifischer Produkte.,使定制化产品得到了品质的保证。',
    // 您身边的纺织专家
    f_r1:'Die Textilexperten um Sie herum',
    f_r2:'7x24 Stunden exzellenter textiler Unterstützung',
    
}