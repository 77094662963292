<template>
  <div>
    <div class="translateByDe visible-xs" @click="changeLang('de')">
      <img src="@/assets/images/de.svg" style="width: 32px;">
    </div>
    <div class="translateByEn visible-xs" @click="changeLang('en')">
      <img src="@/assets/images/en.svg" style="width: 32px;">
    </div>
    <div class="translateByZh visible-xs" @click="changeLang('zh')">
      <img src="@/assets/images/zh.svg" style="width: 32px;">
    </div>
    <div id="GoTop" @click="GoTop()">
      <span class="glyphicon glyphicon-chevron-up"></span>
    </div>
  </div>

</template>
<script>
export default {
  name: "GoTop",
  data() {
    return {
      flag: false
    };
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem("locale", lang)
      this.$i18n.locale = lang
      location.reload()
    },
    GoTop() {
      (function smoothscroll() {
        var currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - currentScroll / 10);
        }
      })();
    }
  }
};
</script>
<style scoped>
.translateByDe {
  position: fixed;
  right: 40px;
  bottom: 220px;
  z-index: 99999999;
  cursor: pointer;
}

.translateByEn {
  position: fixed;
  right: 40px;
  bottom: 160px;
  z-index: 99999999;
  cursor: pointer;
}

.translateByZh {
  position: fixed;
  right: 40px;
  bottom: 100px;
  z-index: 99999999;
  cursor: pointer;
}

#GoTop {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999999;
  cursor: pointer;
}

#GoTop>span {
  display: block;
  width: 100%;
  height: 100%;
  color: rgb(8, 162, 233);
  font-size: 30px;
}
</style>
