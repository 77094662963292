import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n); // 全局挂载
let lang = ''
console.log(localStorage.getItem("locale"))
if (localStorage.getItem("locale") === null) {
  if (navigator.language === 'zh-CN') {
    lang = 'zh'
  } else if (navigator.language === 'de') {
    lang = 'de'
  } else {
    lang = 'en'
  }
}
export const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || lang, // 从localStorage中获取 默认英文
  messages: {
    zh: require("./lan/zh"), // 中文语言包
    en: require("./lan/en"), // 英文语言包
    de: require("./lan/de"), // 中文语言包
  }
});

export default i18n;