<template>
  <div id="footer" class="container-fluid">
    <p class="copy">Copyright &copy; 2023 - {{ new Date().getFullYear() }} 浙江越隆缝制设备有限公司 </p>
    <p ><a class="copy" href="https://beian.miit.gov.cn">浙ICP备19039693号-1</a></p>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  }
};
</script>
<style scoped>
#footer {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #474747;
  overflow: hidden;
  text-align: center;
}

.copy {
  color: #d3d3d3;
  font-size: 14px;
  margin: 10px 0 10px;
}

@media screen and (max-width: 997px) {
  .copy {
    font-size: 12px;
    margin: 10px 0 10px;
  }
}
</style>
