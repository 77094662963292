export const h = {
    // 通用
    c_btn1: '了解更多',
    c_btn2: '查看色度',
    c_btn3: '联系我们',
    // 头部
    translate: '切换到英文',
    company: '越美绣机',
    service: '7x24小时为您服务',
    contactUs: '赶快联系我们吧！',
    // 菜单
    home: '首页',
    embroidery: '刺绣',
    printting: '印刷',
    others: '其他产品',
    financing: '融资',
    blogs: '博客',
    exhibitions: '展览活动',
    connectUs: '联系我们',
    // banner
    b_r1c1: '充分发挥您的定制',
    b_r1c2: '服装业务的潜力',
    b_r2c1: '利用业内最好的设备、支持和培训启动或发展盈利的定制服装业务',
    // video
    v_r1: '浙江越隆缝制设备有限公司(以下简称:越隆公司)成立于2001年,“越美绣机”是产品商标。越隆公司是一家集产品研发、设计、生产、销售、股务于一体的专业多头电脑利绣机生产厂家。',
    v_r2: '越隆公司注册资金7500万元,厂区占地约150亩,建筑面积约10万多平方米,拥有固定资产约6.3亿元,是国内多头电脑绣花机行业的龙头企业。公司现拥有员工620人,其中研发技术人员67人,本科及以上37人(其中研究生2人),高级工51人。',
    v_r3: '“诚信、感思、和谐、创新 ”是企业的价值理；“致力于打造世界一流的电脑刺绣机制造商”是企业的须思；“永不平庸,永不放弃,永不满足"是企业不断进取的精神；“刺绣让生活更美好"是企业的使命。',
    v_r4: '越隆公司评选为国家级高新技术企业,先后通过了ISO-9001质量管理体系认证和IS0-14001环境管理体系认证,国际CE认证。',
    v_r5: '荣获工信部单项冠军产品、浙江省首台套产品、浙江制造精品,浙江省科技奖、浙江省名牌、浙江省出口名牌、浙江省隐形冠军培育企业、绍兴市首台餐产品、绍兴市隐形冠军、绍兴市著名商标等荣誉称号。',
    v_r6: '越隆公司是“浙江制造”标准《计算机控制高速雕孔刺绣机》的主起草企业,是国家标准GB/T30402.2-201的主要起草企业,是中国轻工业装备制造三十强企业、中国轻工业缝制机械刺绣行业十强榜首和浙江省“品质浙货”出口领军企业。',
    // 为什么选择我们
    u_r1: '为什么选择我们的服务',
    u_r2c1: '首屈一指的客户支持',
    u_r2c2: 'YUEMEI客户可享受1年保修、免费培训以及每周7天终生使用我们的服务部门-无需额外支付一毛钱。',
    u_r3c1: '直接从工厂到您家门口',
    u_r3c2: '直接从制造商购买意味着您将获得最直接的支持，且您无需支付更多费用来削减中间商的费用。',
    u_r4c1: '服装定制的最高品质',
    u_r4c2: 'YUEMEI机器重型且专为 24/7 运行而设计将以精度和速度征服每个项目。',
    // 融资
    g_r1: '无与伦比的融资100%满意',
    g_r2: '想象一下，只需支付电话费或有网络流量费即可开始或发展您的业务。通过特殊的促销融资，从您的业务中获利从未如此简单。您节省的前期费用?您口袋里的钱可以用来做广告和扩展您的业务。',
    // 明星产品
    p_r1c1: '明星产品',
    p_r1c2: '无论您是新手还是经过认证的刺绣高手，',
    p_r1c3: '我们易于使用的机器都将帮助您立即上手并运行。',
    p_title: '刺绣爱好者',
    p_r1: '双头12针入门级',
    p_r2: '单头混合秀12针',
    p_r3: '12针',
    p_t1: '使用这款',
    p_t2: '刺绣创造令人惊叹的多色顶级项目。',
    // 培训
    t_r1: '通过免费培训和支持立即开始赚钱',
    t_r2: '论您是刺绣新手还是第一次购买YUEMEI机器,每个YUEMEI机器套餐都包含免费的实践培训和每周7天的无限终身支持。我们将立即帮助您启动并运行,以便您可以立即开始从您的投资中获利。',
    // 提高您的刺绣设计技能
    c_r1: '提高您的刺绣设计技能',
    c_r2: '没有经验?没问题。新手可以使用这款适合初学者的软件轻松数字化和编辑设计。每台YUEMEI刺绣机都包含专用的设计软件',
    // 运行情况
    r_r1: '经验丰富的设计团队',
    r_r2: '经过多年的生产制造的经验总结，已经完善了一套可靠的机器参数。',
    r_r3: '经验丰富的设计团队根据总结的机器参数做出合格标准的变型设计图纸,使定制化产品得到了品质的保证。',
    // 您身边的纺织专家
    f_r1:'您身边的纺织专家',
    f_r2:'7x24小时提供出色的纺织支持',
    
}